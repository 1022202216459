import axios from 'axios';

const qs = require('qs');

const instance = axios.create({
  // baseURL: 'http://47.97.201.223:7852',
  // baseURL: 'http://192.168.110.126:8080',
  baseURL: '/api',
  // baseURL: 'http://85bc7i.natappfree.cc',
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 30000,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

// request拦截器
instance.interceptors.request.use((config) => {
  // 如果是put/post请求，用qs.stringify序列化参数
  const is_put_post = config.method === 'put' || config.method === 'post';
  const is_json = config.headers['Content-Type'] === 'application/json';
  if (is_put_post && is_json) {
    config.data = JSON.stringify(config.data);
  }
  if (is_put_post && !is_json) {
    config.data = qs.stringify(config.data, { arrayFormat: 'repeat' });
  }
  // Do something before request is sent

  return config;
}, (error) => {
  Promise.reject(error);
});

/* 获取预约单列表
@param params 参数 */

export const getList = (params) => instance({
  url: '/api/small-work-order/info',
  method: 'post',
  data: params,
});

/* getJSTicketData
@param params 参数 */

export const getJSTicketData = (params) => instance({
  url: '/api/getJSTicketData',
  method: 'post',
  data: params,
});
