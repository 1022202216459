import Vue from 'vue';
// import Vant from 'vant';
import {
  Button,
  Cell,
  CellGroup,
  Popup,
  Field,
  Loading,
  Toast,
  List,
  PullRefresh,
  Swipe,
  SwipeItem,
  Form,
  Dialog,
  Icon,
  Row,
  Col,
  Overlay,
} from 'vant';
import 'vant/lib/index.css';

// Vue.use(Vant);
Vue.use(Button);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Loading);
Vue.use(Toast);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Form);
Vue.use(Dialog);
Vue.use(Icon);
Vue.use(Row);
Vue.use(Col);
Vue.use(Overlay);
