<template>
  <div style="height: 100%;" class="detail">
    <div class="header">
      <div class="header-container">
        <van-icon @click="goBack" class="back-icon" name="arrow-left" />
        <h4>{{ deptInfo.khmc }}</h4>
      </div>
    </div>
    <div class="notice">
      <div class="card">
        尊敬的客户，贵公司实时总欠款为：{{deptInfo.qkje}}元，以下为合同{{deptInfo.dh}}的完成情况：
      </div>
    </div>
    <div class="container">
      <van-list
        v-if="reserveList.length !== 0"
        class="reserve-list"
      >
        <template v-for="(reserve, index) in reserveList">
          <div
            class="reserve-item"
            :key="index"
            :class="reserve.zxzt == 2 && reserve.fhbz == 0?'ing'
            :reserve.zxzt == 5 && reserve.fhbz == 0?'pipe'
            :reserve.zxzt == 9 && reserve.fhbz == 0?'ed'
            :reserve.zxzt == 11 || reserve.fhbz == 1?'dis':''"
          >
            <p class="info">
              {{index+1}}.
              {{reserve.cpxx}}
            </p>
            <p>
              <span style="flex: 1;">{{reserve.slxx}}</span>
              <span class="state" style="flex: 1;text-align: right;">
                <span v-show="(reserve.zxzt == 0||reserve.zxzt == 1)&& reserve.fhbz == 0">待加工</span>
                <span v-show="reserve.zxzt == 2 && reserve.fhbz == 0">加工中</span>
                <span v-show="reserve.zxzt == 5 && reserve.fhbz == 0">
                  已完成
                  <span style="color:#ffa441">待打包</span>
                </span>
                <span v-show="reserve.zxzt == 9 && reserve.fhbz == 0">已完成已打包</span>
                <span v-show="reserve.zxzt == 11 || reserve.fhbz == 1">已发货</span>
              </span>
            </p>
          </div>
        </template>
      </van-list>
      <div v-else class="empty-box">
        <img src="../assets/images/no-result.png" />
        <p>暂无查询结果</p>
      </div>
      <div class="fixLogo">
        <img src="../assets/images/logo.png" />
      </div>
    </div>
  </div>
</template>

<script>
import * as API_reserve from '../api/reserve';
// import { Valid } from '../util/util';

export default {
  name: 'detail',
  data() {
    return {
      deptInfo: {
        khmc: '', // 杭州善锋不锈钢有限公司
        fgsmc: '', // 无锡巨人
        qkje: 0,
        dh: '', // H2210220188
      },
      finished: false, // 已查完
      reserveAmount: 0,
      reserveList: [
        // {
        //   slxx: '平板',
        //   xh: '201J5',
        //   cd: '诚德',
        //   cz: '2B',
        //   gg: '0.95*1219*2500',
        //   num: 325,
        //   unit: '件',
        //   status: '待加工',
        //   zxzt: 2,
        //   fhbz: 0,
        // },
        // {
        //   slxx: '平板',
        //   xh: '201J5',
        //   cd: '诚德',
        //   cz: '2B',
        //   gg: '0.95*1219*2500',
        //   num: 24,
        //   unit: '件',
        //   status: '加工中',
        //   zxzt: 5,
        //   fhbz: 0,
        // },
        // {
        //   slxx: '平板',
        //   xh: '201J5',
        //   cd: '诚德',
        //   cz: '2B',
        //   gg: '0.95*1219*2500',
        //   num: 24,
        //   unit: '件',
        //   status: '已完成',
        //   zxzt: 9,
        //   fhbz: 0,
        // },
        // {
        //   slxx: '平板',
        //   xh: '201J5',
        //   cd: '诚德',
        //   cz: '2B',
        //   gg: '0.95*1219*2500',
        //   num: 24,
        //   unit: '件',
        //   status: '已完成',
        //   zxzt: 11,
        //   fhbz: 1,
        // },
      ],
      modifiedList: [],

      code: '',
      state: '',
      code2: null,
      openid: '',

      shadow: new Array(8),
      clickIndex: 0,

      obj: {},
    };
  },
  filters: {
    billStateFormat(value) {
      switch (parseInt(value, 10)) {
        case 1:
          return '已预约';
        case 2:
          return '已提货';
        case 3:
          return '已取消';
        case 4:
          return '已逾期';
        default:
          return '状态错误';
      }
    },
    dateFormat(str) {
      return str ? str.trim().substr(0, 10) : '';
    },
    toggleBtnFormat(arr) {
      if (arr.productInfo && arr.productInfoShow) {
        return '收起产品';
      }
      return '查看产品';
    },
  },
  watch: {

  },
  // beforeRouteEnter(to, from, next) {
  //   // to是当前页面,from是从哪里来,next是放行
  //   if (to.name === 'detail') {
  //     // console.log('to.meta.title', to.meta.title);
  //     document.title = '无锡巨人金属材料有限公司';
  //   }
  //   next(); // 切记操作完一定要记得放行,否则无法正常跳转页面
  // },
  activated() {
    document.title = '客户加工进度查询';
    this.getList();
  },
  mounted() {
    console.log(this.$route.params);
    this.obj = JSON.parse(JSON.stringify(this.$route.params));
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getList() {
      const that = this;
      console.log('that.obj', that.obj);
      API_reserve.getList(that.obj)
        .then((res) => {
          console.log(res);
          if (res.data.code === 500) {
            that.$dialog.alert({
              message: res.data.msg,
              showConfirmButton: true,
            }).then(() => {
              that.$router.go(-1);
            });
            return false;
          }
          const { data } = res.data;
          that.reserveList = JSON.parse(JSON.stringify(data));
          const obj = that.reserveList[0];
          that.deptInfo = JSON.parse(JSON.stringify(obj));
          console.log(that.deptInfo);
          document.title = this.deptInfo.fgsmc;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    goUrl(url) {
      window.location.href = url;
    },
  },
};
</script>

<style lang="scss" scoped>
.welcome{
  padding: 75px 30px 0 30px;
  @include display-flex;
  flex-direction: column;
  .welcome-text{
    font-size: 24px;
    font-weight: bold;
    height: 36px;
    line-height: 36px;
  }
  .welcome-tip{
      font-size: 14px;
      color: #ee0a24;
      margin-top: 15px;
  }
}
.info-tip{
    font-size: 16px;
    color: #ee0a24;
    font-weight: bold;
    padding: 0 10px;
    text-align: center;
    background-color: #fff;
    height: 23px;
    position: fixed;
    top: 45px;
    left: 0;
    z-index: 10;
    width: 100%;
}
.login-form{
  padding: 0 30px;
  padding-bottom: 30px;
  .form-item{
    position: relative;
    border-bottom: 1px solid #ddd;
    padding-top: 15px;
    .form-label{
      position: absolute;
      top: 10px;
      font-size: 10px;
      color: #8a8a8a;
      height: 15px;
      line-height: 15px;
    }
    .form-input-box{
      @include display-flex;
      flex-direction: row;
      flex-wrap: nowrap;
      .form-input{
        @include flex-number;
        height: 44px;
        line-height: 44px;
        font-size: 18px;
        color: #333;
        text-align: center;
      }
    }
  }
  .login-btn{
    display: block;
    margin-top: 30px;
    width: 100%;
    height: 44px;
    line-height: 44px;
    padding: 0;
    @include border-radius(22px);
    font-size: 16px;
    color: #fff;
    background-color: $base-color;
    text-align: center;
    border: none;
    &::after{
      border: none;
    }
  }
}

.reserve-amount{
  font-size: 18px;
  color: #e6a23c;
  padding: 0 2px;
}
.reserve-list{
  .reserve-owner{
    width: 100%;
    height: 36px;
    line-height: 36px;
    font-size: 15px;
    border-left: 5px solid #fe6c6f;
    padding-left: 10px;
    background-color: #fff6f7;
    @include border-box;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    margin-top: 15px;
  }
  .reserve-item{
    position: relative;
    padding: 15px;
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    &:first-child{
      border-top: 1px solid #e5e5e5;
    }
    &.ing{
      background: #ffffec;
      .state{
        color: #ffa441;
      }
    }
    &.ed{
      background: #dfffdf;
      p{
        color: #0bcc0a;
      }
      .state{
        color: #0bcc0a;
      }
    }
    &.pipe{
      background: #ffffec;
      p{
        color: #0bcc0a;
      }
      .state{
        color: #0bcc0a;
      }
    }
    &.dis{
      background: #efefef;
      p{
        color: #969696;
      }
      .state{
        color: #969696;
      }
    }
    p{
      line-height: 25px;
      font-size: 13px;
      display: flex;
      &.info {
        display: -webkit-box;
        // overflow: hidden;
        /*! autoprefixer: off; */
        -webkit-box-orient: vertical;
        // -webkit-line-clamp: 2;
        font-weight: bold;
      }
    }
    .more-btn{
      position: absolute;
      top: 71px;
      right: 15px;
      padding: 0 7px;
      height: 24px;
      line-height: 24px;
      font-size: 12px;
      color: #fff;
      background-color: $base-color;
      @include border-radius(4px);
    }
    .product-info{
      margin-top: 15px;
      padding: 5px 15px;
      @include border-radius(8px);
      background-color: #f1f3f4;
      table{
        width: 100%;
        th,td{
          // width: 50%;
          text-align: left;
          font-size: 13px;
        }
      }
    }
  }
}
.empty-box{
  padding-top: 100px;
  img{
    width: 100px;
    height: 100px;
    margin: 0 auto;
  }
  p{
    font-size: 16px;
    color: #999;
    text-align: center;
    line-height: 36px;
  }
}
.footer_b{
  text-align: center;
  font-size: 14px;
  color: #333;
  margin-top: 20px;
}

</style>
<style lang="scss" scoped>
  .cph-wrap {
        display: flex;
        justify-content: flex-start;
        font-size: 18px;
        line-height: 40px;
        min-height: 40px;

        .active {
            width: 40px;
            height: 40px;
            background-color: #CFD3DD;
        }
    }

    .cph-wrap-shadow {
        background-color: #fff;
        z-index: 0;
        margin: 16px 10px;
    }

    .cph-wrap-shadow span {
        font-size: 18px;
    }

    .cph-wrap span {
        position: relative;
        text-align: center;
        width: 40px;
        height: 40px;
        border-radius: 2px;
        border: 1px solid #666;
        margin-right: 7px;
        background-color: #fff;
        color: #000;
        z-index: 99;
    }

    .cph-wrap span:first-of-type {
        margin-left: 0;
    }

    .cph-wrap span:nth-of-type(8) {
        margin-right: 0;
    }

    .cph-wrap span:last-child::before {
        content: none;
    }

    .cph-wrap2 {
        display: flex;
        justify-content: flex-start;
        font-size: 18px;
        line-height: 40px;
        min-height: 40px;

        .active {
            width: 40px;
            height: 40px;
            background-color: #CFD3DD;
        }
    }

    .cph-wrap-shadow2 {
        background-color: #fff;
        z-index: 0;
        margin: 30px 10px;
    }

    .cph-wrap-shadow2 span {
        font-size: 18px;
    }

    .keyboard-wrap {
        background: #CFD3DD;
        padding: 0 2px 0;

        .keyboardHeader {
            display: flex;
            background-color: #fff;
            margin: 1px -2px 10px -2px;
            line-height: 40px;
            justify-content: space-between;
            align-items: center;
            .specials {
                font-size: 18px;
                margin-left: 12px;
                color: #222;
            }

            .kspecials {
                color: #ccc;
            }

        }
    }

    .keyboard {
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        position: relative;

        span {
            background: #fff;
            border-radius: 4px;
            box-shadow: 0 1px 2px #666;
            width: calc(10% - 4px);
            text-align: center;
            height: 46px;
            line-height: 46px;
            margin: 0 2px 8px;
            font-size: 16px;
        }

        .k-del {
            width: 40px;
            position: absolute;
            right: 2px;
            display: block;
            color: red;
        }

        .k-del img {
            display: block;
            margin: 9px auto;
        }

        .k-del2 {
            color: red;
        }

    }
</style>
