<template>
    <div style="height:100%;background: #fff">
        <!-- <div id="header" class="header">
            <a @click="goBack"><img src="../assets/images/return.png" alt=""></a>
            扫码
        </div> -->
        <div class="scan container">
            <div class="main">
              <h4 class="titleText">客户加工进度查询</h4>
              <van-button type="primary" size="large" class="smBtn" @click="scan">
                <img src="../assets/images/scan.png" />
                点击扫一扫
              </van-button>
              <!-- <van-field v-model="DHvalue" placeholder="手工输入单号" class="sgInput"/>
            <van-button round type="primary" class="yesBtn" @click="showDetail">确认</van-button> -->
            </div>
        </div>
        <van-dialog v-model="dialogShow" title="授权码" show-cancel-button :before-close="beforeClose">
          <van-field v-model="code" placeholder="请输入授权码" class="sgInput"
          :maxlength="6" type="number"/>
        </van-dialog>
        <div class="fixLogo">
          <img src="../assets/images/logo.png" />
        </div>
    </div>
</template>

<script>
import * as API_reserve from '../api/reserve';

export default {
  name: 'scan',
  data() {
    return {
      DHvalue: '',
      dialogShow: false,
      code: '',
      query: {},
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === 'detail') {
      window.location.reload();
    }
    next();
  },
  activated() {
    document.title = '客户加工进度查询';
  },
  mounted() {
    this.init();
    const { query } = this.$route;
    console.log('query', query);
    if (query.dh && query.an) {
      this.query = query;
      this.code = '';
      this.dialogShow = true;
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    scan() {
      // this.code = '';
      // this.dialogShow = true;
      const me = this;
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
        success(res) {
          me.DHvalue = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
          me.code = '';
          me.dialogShow = true;
        },
      });
    },
    init() {
      API_reserve.getJSTicketData({
        // jsUrl:'http://xh.steelsoso.com?id=' + queryId + '&active=' + active +'&plate=' + plate
        jsUrl: window.location.href,
        // jsUrl: 'wxplatform.rxgmy.com',
      }).then((res) => {
        console.log(res.data);
        const param = res.data;
        wx.config({
          // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          debug: false,
          appId: param.appId, // 必填，公众号的唯一标识
          timestamp: param.timestamp, // 必填，生成签名的时间戳
          nonceStr: param.nonceStr, // 必填，生成签名的随机串
          signature: param.signature, // 必填，签名
          jsApiList: ['scanQRCode'], // 必填，需要使用的JS接口列表
        });

        wx.ready(() => { // 需在用户可能点击分享按钮前就先调用

        });
        wx.error((e) => {
          console.log(e);
        });
      }).catch((error) => {
        console.log(error); // 请求失败返回的数据
      });
    },

    beforeClose(action, done) {
      const me = this;
      if (action === 'confirm') {
        if (me.code === '' || me.code == null || me.code === undefined) {
          me.$toast('授权码为空！');
          done(false);
        } else if (me.code.length < 4) {
          me.$toast('授权码为4到6位！');
          done(false);
        } else {
          // me.DHvalue = 'XS001221201002#yvcltcswolqyrzkolvgvnujwankedurj#00002581';
          done();
          setTimeout(() => {
            me.showDetail();
          }, 10);
        }
      } else {
        done();
      }
    },

    // 点击进二级页明细
    showDetail() {
      const me = this;
      if (me.query.dh !== '' || (me.DHvalue !== '' && me.DHvalue != null && me.DHvalue !== undefined)) {
        let params;
        if (me.DHvalue) {
          // XS001221201002#wxjrgerp20221210#002158
          // 单号dh#账套编码an#系统编码uc
          // accountNum       账套编码
          // orderNum         单号
          // unitCode         单位编码
          // code             授权码
          params = {
            accountNum: me.DHvalue.split('#')[1],
            orderNum: me.DHvalue.split('#')[0],
            // unitCode: me.DHvalue.split('#')[2],
            code: me.code,
          };
        } else {
          params = {
            accountNum: me.query.an,
            orderNum: me.query.dh,
            // unitCode: me.query.uc,
            code: me.code,
          };
        }
        me.$router.push({
          name: 'detail',
          params,
        });
      } else {
        me.$dialog.alert({
          message: '单号为空！请重新扫码',
          showConfirmButton: true,
        });
        me.dialogShow = false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    /* @import '../lib/aui.css' ; */
    /* @import '../lib/style_scan.css'; */

    .container {
        display: table;
        width: 100%;
        height: 80%;
        text-align: center;
        background: #fff;
    }

    .container .main {
        display: table-cell;
        vertical-align: middle;
    }

    .titleText {
      font-size: 24px;
      color: #575757;
      margin-bottom: 30px;
    }

    .smBtn {
        width: 160px;
        height: 160px;
        border-radius: 50%;
        background: #1aa4fc;
        border: none;
    }

    .smBtn img {
        width: 80px;
        margin-bottom: 10px;
      }

    .sgInput {
        border: 1px solid #dcdee0;
        margin: 30px auto;
        width: 230px;
        padding: 18px 15px;
        font-size: 18px;
    }

    .yesBtn {
        width: 100px;
    }

    .fixLogo {
      position: fixed;
      bottom: 10px;
      width: 100%;
    }
</style>
