import Vue from 'vue';
import VueRouter from 'vue-router';
import scan from '../views/scan.vue';
import detail from '../views/detail.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'scan',
    component: scan,
    meta: {
      keepAlive: true,
      title: '扫码',
    },
  },
  {
    path: '/detail',
    name: 'detail',
    component: detail,
    meta: {
      keepAlive: true,
      title: '明细',
    },
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

export default router;
