import Vue from 'vue';
import FastClick from 'fastclick';
import axios from 'axios';
import qs from 'qs';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/vant';
import './assets/scss/public.scss';
import global from './global/global';

FastClick.prototype.focus = function (targetElement) {
  let length;
  // 这是因为这些元素并没有selectionStart和selectionEnd的整型数字属性，所以一旦引用就会报错，因此排除这些属性才使用setSelectionRange方法
  if (targetElement.setSelectionRange && targetElement.type.indexOf('date') !== 0 && targetElement.type !== 'time' && targetElement.type !== 'month') {
    length = targetElement.value.length;
    targetElement.focus();
    targetElement.setSelectionRange(length, length);// 修复bug ios 11.3不弹出键盘，这里加上聚焦代码，让其强制聚焦弹出键盘
  } else {
    targetElement.focus();
  }
};
FastClick.attach(document.body);

Vue.prototype.$http = axios;
Vue.prototype.$qs = qs;
Vue.prototype.$global = global;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
